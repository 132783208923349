import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { createBrowserHistory } from "history";

const reactPlugin = new ReactPlugin();
const browserHistory = createBrowserHistory();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: `InstrumentationKey=${process.env.REACT_APP_INSTRUMENTATION_KEY};IngestionEndpoint=${process.env.REACT_APP_INSIGHTS_URL}`,
    extensions: [reactPlugin as any],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
    disableExceptionTracking: false,
  },
});

// Don't load the AppInsights if the connection string will not be valid
if (
  process.env.REACT_APP_INSTRUMENTATION_KEY &&
  process.env.REACT_APP_INSTRUMENTATION_KEY.trim() !== "" &&
  process.env.REACT_APP_INSTRUMENTATION_KEY !== "[INSTRUMENTATION_KEY_HERE]" &&
  process.env.REACT_APP_INSIGHTS_URL &&
  process.env.REACT_APP_INSIGHTS_URL.trim() !== "" &&
  !process.env.REACT_APP_INSIGHTS_URL.endsWith("[Region c | d + app insights url number 0 | 1]")
) {
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}

export { appInsights, reactPlugin };
