import { MakeAPaymentApiClient } from "raci-make-a-payment-clientproxy";
import { useSessionIdStorageKey, useSsoApiClient } from "raci-react-library";
import { ADB2C_CLIENT_ID, BFF_API_BASE_URL } from "../../constants";

const useRealBffApiClient = () => {
  const sessionIdStorageKey = useSessionIdStorageKey();
  return useSsoApiClient({
    Client: MakeAPaymentApiClient,
    clientId: ADB2C_CLIENT_ID,
    apiBaseUrl: BFF_API_BASE_URL,
    sessionIdStorageKey,
  });
};

export let useApiClient = useRealBffApiClient;

/*
 ** WARNING: KNOW WHAT YOU'RE DOING BEFORE MAKING CHANGES TO
 ** TO THE BELOW IF STATEMENT.
 ** UPON A BUILD, REACT SCRIPT WILL REMOVE CODE IN
 ** THE OUTPUTTED JAVASCRIPT FILE DEPENDING ON THE TARGET
 ** ENVIRONMENT
 ** THIS MEANS THAT FOR LOCAL/DEV/SIT BUILDS WILL INCLUDE
 ** AND SHIP CODE (YOU CAN SEE AND SEARCH FOR IN OUTPUTTED
 ** JAVASCRIPT ARTIFACTS) FOR MICROSOFT AUTHENTICATION LIBRARY
 */
if (
  process.env.REACT_APP_ENVIRONMENT === "LOCAL" ||
  process.env.REACT_APP_ENVIRONMENT === "DEV" ||
  process.env.REACT_APP_ENVIRONMENT === "SIT" ||
  process.env.REACT_APP_ENVIRONMENT === "UAT"
) {
  const { BypassService } = require("raci-react-library-npe");
  const { useNpeBffApiClient } = require("../../../__NPE__/useApiClient");
  useApiClient = useNpeBffApiClient;

  if (process.env.REACT_APP_ENVIRONMENT === "UAT" && !BypassService.isUsingTestLaunchPage()) {
    useApiClient = useRealBffApiClient;
  }
}

export default useApiClient;
