import { useHasSession, useSessionIdStorageKey } from "raci-react-library";

/**
 * Determine if the session is valid based on:
 * - The session status return by the SessionStatusProvider
 * - There being a valid session ID in the session storage
 *
 * Only use once useCreateSession has actually created the session, so don't use in:
 * - The useCreateSession hook
 * - The FlowStateProvider
 */
export const useHasValidSession = () => {
  const hasSession = useHasSession();
  const sessionIdStorageKey = useSessionIdStorageKey();

  const sessionId = sessionStorage.getItem(sessionIdStorageKey) ?? "";
  const hasValidSession = hasSession && !!sessionId.trim();

  return hasValidSession;
};

export default useHasValidSession;
