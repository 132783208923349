import { useGetPreviousPageSessionState } from "raci-react-library";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useFlowState from "../../hooks/useFlowState";
import {
  POLICY_CONFIRMATION_PAGE_URL,
  UTILITY_CALL_US_PAGE_URL,
  UTILITY_SESSION_TIMEOUT_PAGE_URL,
  UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
} from "../routes.config";

export interface RouteGuardProps {
  disableChecks?: string[];
}

/**
 * Utility Error pages that redirect to default error page (SystemUnavailable)
 * when FlowState.HasError is true and current location is not in the array.
 */
const UTILITY_ERROR_PAGE_URLS = [UTILITY_CALL_US_PAGE_URL, UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL];

export const RouteGuard = ({ disableChecks = [] }: RouteGuardProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [flowState] = useFlowState();
  const { path: previousPageUrl, isCompleted: isPreviousPageCompleted } = useGetPreviousPageSessionState();

  const currentLocation = location.pathname.toLowerCase();
  const hasTimedOut = flowState.hasTimedOut;
  const hasError = flowState.hasError;
  const isCompleted = flowState.isCompleted;

  useEffect(() => {
    if (hasTimedOut) {
      if (currentLocation !== UTILITY_SESSION_TIMEOUT_PAGE_URL) {
        // Redirect user back to confirmation if user tries to navigate away
        navigate(UTILITY_SESSION_TIMEOUT_PAGE_URL, {
          state: {
            referrer: location.pathname,
          },
        });
      }
    } else if (isCompleted) {
      if (currentLocation !== POLICY_CONFIRMATION_PAGE_URL) {
        // Redirect user back to confirmation if user tries to navigate away
        navigate(POLICY_CONFIRMATION_PAGE_URL);
      }
    } else if (hasError) {
      if (!UTILITY_ERROR_PAGE_URLS.includes(currentLocation)) {
        // Redirect user system unavailable page if user tries to navigate away from error page
        navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL, {
          state: {
            referrer: location.pathname,
          },
        });
      }
    } else {
      const bypass = disableChecks.filter((item) => item.toLowerCase() === currentLocation).length > 0;
      if (!bypass && previousPageUrl && !isPreviousPageCompleted) {
        // Navigate user to previous page if allowed
        // Replace browser history as user should not be able to navigate back to where they came from
        navigate(previousPageUrl, { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableChecks, currentLocation, hasTimedOut, hasError, isCompleted, previousPageUrl, isPreviousPageCompleted]);

  return <></>;
};

export default RouteGuard;
