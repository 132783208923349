import { useSessionStateInitialiser } from "raci-react-library";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { RouteInformation, policyRoutes, preFormRoutes } from "../routes.config";

export interface StepInformation {
  name: string;
  path: string;
  heading?: string;
}

export interface UseRoutesResults {
  steps: StepInformation[];
  preFormRoutes: RouteInformation[];
  formRoutes: RouteInformation[];
  activeStepIndex?: number;
  totalStepCount?: number;
}

export const useRoutes = (): UseRoutesResults => {
  const location = useLocation();
  const initialiseSessionStates = useSessionStateInitialiser();

  useEffect(() => {
    initialiseSessionStates(
      formRoutes.map(({ key, path }) => ({
        page: key,
        path,
      })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialiseSessionStates, location.pathname]);

  const formRoutes = policyRoutes;

  const isInPaymentFlow = formRoutes.filter((item) => item.path === location.pathname).length > 0;

  const steps: StepInformation[] = isInPaymentFlow ? formRoutes : [];

  const totalStepCount = steps.length;
  const activeStepIndex = steps.findIndex((item) => item.path === location.pathname);

  return {
    steps,
    preFormRoutes,
    formRoutes,
    activeStepIndex,
    totalStepCount,
  };
};

export default useRoutes;
