import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { RacwaThemeProvider } from "@racwa/react-components";
import {
  Backdrop,
  SessionStatePrefixProvider,
  SessionStatusProvider,
  TrackPageChanges,
  WebChat,
} from "raci-react-library";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import FlowStateProvider from "./shared/components/FlowStateProvider";
import PolicyNumberProvider from "./shared/components/PolicyNumberProvider";
import { SESSION_KEY_PREFIX } from "./shared/constants";
import useCreateSession from "./shared/hooks/useCreateSession";
import useGetWebChatInformation from "./shared/hooks/useGetWebChatInformation";
import { trackPageChangesProps } from "./shared/routing/routes.config";
import { reactPlugin } from "./shared/utils/applicationInsights";
import Views from "./views";

export const App = () => (
  <RacwaThemeProvider>
    <RecoilRoot>
      <AppInsightsContext.Provider value={reactPlugin}>
        <Backdrop />
        <BrowserRouter>
          <SessionStatePrefixProvider prefix={SESSION_KEY_PREFIX}>
            <SessionStatusProvider useCreateSession={useCreateSession}>
              <FlowStateProvider>
                <PolicyNumberProvider>
                  <Views />
                  <WebChat useGetWebChatInformation={useGetWebChatInformation} />
                </PolicyNumberProvider>
              </FlowStateProvider>
            </SessionStatusProvider>
          </SessionStatePrefixProvider>
          <TrackPageChanges {...trackPageChangesProps} />
        </BrowserRouter>
      </AppInsightsContext.Provider>
    </RecoilRoot>
  </RacwaThemeProvider>
);

export default App;
