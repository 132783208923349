import { AtomEffect, DefaultValue, atom } from "recoil";
import { ATOM_RECEIPT_NUMBER, ATOM_SESSION_ID, ATOM_SHOW_WESTPAC_RETRY_DIALOG } from "../constants";

/** https://recoiljs.org/docs/guides/atom-effects/#local-storage-persistence */
const sessionStorageEffect: <T>(key: string) => AtomEffect<T> =
  (key: string) =>
  ({ setSelf, onSet }) => {
    const savedValue = sessionStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue) => {
      if (newValue instanceof DefaultValue) {
        sessionStorage.removeItem(key);
      } else {
        sessionStorage.setItem(key, JSON.stringify(newValue));
      }
    });
  };

export const sessionIdAtom = atom<string>({
  key: ATOM_SESSION_ID,
  default: "",
  effects: [sessionStorageEffect<string>(ATOM_SESSION_ID)],
});

export const receiptNumberAtom = atom<string>({
  key: ATOM_RECEIPT_NUMBER,
  default: "",
  effects: [sessionStorageEffect<string>(ATOM_RECEIPT_NUMBER)],
});

export const westpacRetryDialogAtom = atom<boolean>({
  key: ATOM_SHOW_WESTPAC_RETRY_DIALOG,
  default: false,
  effects: [sessionStorageEffect<boolean>(ATOM_SHOW_WESTPAC_RETRY_DIALOG)],
});
