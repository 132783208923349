// <------------------>
//  Common
// <------------------>
export const SESSION_KEY_PREFIX = "RAC_MAP_";
export const RETRY_AFTER_HEADER_KEY = "retry-after";
export const ANNUAL_PAYMENT_FREQUENCY = "Yearly";
export const MONTHLY_PAYMENT_FREQUENCY = "Monthly";
export const CASH_PAYMENT_METHOD = "Cash";

// <------------------>
//  API URLs
// <------------------>
export const BFF_API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? "";

// <------------------>
//  MyRAC AADB2C Auth
// <------------------>
export const ADB2C_DOMAIN = process.env.REACT_APP_ADB2C_DOMAIN ?? "";
export const ADB2C_TENANT = process.env.REACT_APP_ADB2C_TENANT ?? "";
export const ADB2C_CLIENT_ID = process.env.REACT_APP_ADB2C_CLIENT_ID ?? "";

// <------------------>
//  Session State Keys
// <------------------>
export const PRE_FORM_SESSION_STATE_KEY = "PreForm";
export const PAYMENT_SESSION_STATE_KEY = "Payment";
export const RECEIPT_SESSION_STATE_KEY = "Receipt";

//  ⬆ The keys above are prefixed during the session state initialization stage for our pages.
export const CONTEXT_POLICY_NUMBER_SESSION_KEY = `${SESSION_KEY_PREFIX}ContextPolicyNumber`;
export const CONTEXT_FLOW_STATE_SESSION_KEY = `${SESSION_KEY_PREFIX}FlowState`;

// <------------------>
//  Atoms
// <------------------>
export const ATOM_SESSION_ID = `${SESSION_KEY_PREFIX}AtomSessionId`;
export const ATOM_RECEIPT_NUMBER = `${SESSION_KEY_PREFIX}AtomReceiptNumber`;
export const ATOM_SHOW_WESTPAC_RETRY_DIALOG = `${SESSION_KEY_PREFIX}AtomShowWestpacDialog`;

// <------------------>
//  Titles
// <------------------>
export const PAGE_TITLE = "Make a payment";
export const SIDEBAR_TITLE = "Make a payment";
