import { useSessionTimeRemaining } from "raci-react-library";
import useFlowState from "../useFlowState";

export const useOnExpiry = () => {
  const [flowState, setFlowState] = useFlowState();
  const { setTimeoutTime } = useSessionTimeRemaining();

  /**
   * Set null timeout time for SessionTimeoutDialog and hasTimedOut in FlowState.
   * Navigation to SessionTimeout page will be handled in RouteGuard.
   */
  const setTimedOutState = () => {
    setTimeoutTime(null);
    setFlowState({ ...flowState, hasTimedOut: true });
  };

  return setTimedOutState;
};

export default useOnExpiry;
