import { TrackPageChangesProps } from "raci-react-library";
import { lazy } from "react";

const CallUs = lazy(() => import("../../views/CallUs"));
const SessionTimeout = lazy(() => import("../../views/SessionTimeout"));
const SystemUnavailable = lazy(() => import("../../views/SystemUnavailable"));

const PreForm = lazy(() => import("../../views/PreForm"));
const Payment = lazy(() => import("../../views/Payment"));
const Confirmation = lazy(() => import("../../views/Confirmation"));

export enum PreFormRoute {
  PreForm = "",
}

export enum PolicyRoutes {
  Payment = "payment",
  Confirmation = "confirmation",
}

export enum UtilityRoutes {
  CallUs = "call-us",
  SessionTimeout = "408",
  SystemUnavailable = "error",
}

export enum PreFormPageNames {
  PreForm = "",
}

export enum PolicyPageNames {
  Payment = "Payment",
  Confirmation = "Confirmation",
}

export enum UtilityPageNames {
  CallUs = "Call us",
  SessionTimeout = "Session timed out",
  SystemUnavailable = "System unavailable",
}

export enum PolicyPageHeadings {
  Payment = "Payment",
}

export enum UtilityPageHeadings {
  CallUs = "Uh oh!",
  SessionTimeout = "Uh oh!",
  SystemUnavailable = "Uh oh!",
  Default = "Uh oh!",
}

export const POLICY_BASE_URL = `${process.env.REACT_APP_HOMEPAGE}/policy`;

export const PRE_FORM_PAGE_URL = `${process.env.REACT_APP_HOMEPAGE}`;

export const getPolicyRouteUrl = (route: PolicyRoutes) => `${POLICY_BASE_URL}/${route}`;
export const getUtilityRouteUrl = (route: UtilityRoutes) => `${process.env.REACT_APP_HOMEPAGE}/${route}`;

export const POLICY_PAYMENT_PAGE_URL = getPolicyRouteUrl(PolicyRoutes.Payment);
export const POLICY_CONFIRMATION_PAGE_URL = getPolicyRouteUrl(PolicyRoutes.Confirmation);

export const UTILITY_SESSION_TIMEOUT_PAGE_URL = getUtilityRouteUrl(UtilityRoutes.SessionTimeout);
export const UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL = getUtilityRouteUrl(UtilityRoutes.SystemUnavailable);
export const UTILITY_CALL_US_PAGE_URL = getUtilityRouteUrl(UtilityRoutes.CallUs);

export interface RouteInformation {
  key: string;
  path: string;
  name: string;
  element: JSX.Element;
  heading?: string;
}

const preFormRoutesInfo: Record<PreFormRoute, RouteInformation> = {
  [PreFormRoute.PreForm]: {
    key: "pre-form",
    path: PRE_FORM_PAGE_URL,
    name: PreFormPageNames.PreForm,
    element: <PreForm />,
  },
};

export const policyRoutesInfo: Record<PolicyRoutes, RouteInformation> = {
  [PolicyRoutes.Payment]: {
    key: PolicyRoutes.Payment,
    path: POLICY_PAYMENT_PAGE_URL,
    name: PolicyPageNames.Payment,
    element: <Payment />,
    heading: PolicyPageHeadings.Payment,
  },
  [PolicyRoutes.Confirmation]: {
    key: PolicyRoutes.Confirmation,
    path: POLICY_CONFIRMATION_PAGE_URL,
    name: PolicyPageNames.Confirmation,
    element: <Confirmation />,
  },
};

export const utilityRoutes: RouteInformation[] = [
  {
    key: UtilityRoutes.CallUs,
    path: UTILITY_CALL_US_PAGE_URL,
    name: UtilityPageNames.CallUs,
    element: <CallUs />,
    heading: UtilityPageHeadings.CallUs,
  },
  {
    key: UtilityRoutes.SessionTimeout,
    path: UTILITY_SESSION_TIMEOUT_PAGE_URL,
    name: UtilityPageNames.SessionTimeout,
    element: <SessionTimeout />,
    heading: UtilityPageHeadings.SessionTimeout,
  },
  {
    key: UtilityRoutes.SystemUnavailable,
    path: UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
    name: UtilityPageNames.SystemUnavailable,
    element: <SystemUnavailable />,
    heading: UtilityPageHeadings.SystemUnavailable,
  },
];

export const preFormRoutes: RouteInformation[] = [preFormRoutesInfo[PreFormRoute.PreForm]];
export const policyRoutes: RouteInformation[] = Object.values(policyRoutesInfo);
export const allRoutes = [...policyRoutes, ...utilityRoutes];

/**
 * Disable RouteGuard checks for all utility pages and for all confirmation pages.
 * Once confirmation page has been reached, user should not be able to navigate back from confirmation.
 */
export const disableRouteGuardChecks = utilityRoutes
  .map((item) => item.path)
  .concat(PRE_FORM_PAGE_URL)
  .concat(POLICY_CONFIRMATION_PAGE_URL);

export const trackPageChangesProps: TrackPageChangesProps = {
  customAnalyticsUrls: [],
  untrackedPages: [
    PRE_FORM_PAGE_URL,
    UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
    UTILITY_SESSION_TIMEOUT_PAGE_URL,
    UTILITY_CALL_US_PAGE_URL,
  ],
};
