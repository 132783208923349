import { MakeAPaymentApiClient } from "raci-make-a-payment-clientproxy";
import { useSessionIdStorageKey } from "raci-react-library";
import { useSsoApiClient } from "raci-react-library-npe";
import { ADB2C_CLIENT_ID, BFF_API_BASE_URL } from "../../shared/constants";
import { ENVIRONMENT, NPE_OAUTH_AUTHORITY, NPE_OAUTH_CLIENT_ID } from "../constants";

export const useNpeBffApiClient = () => {
  const sessionIdStorageKey = useSessionIdStorageKey();
  return useSsoApiClient({
    Client: MakeAPaymentApiClient,
    clientId: ADB2C_CLIENT_ID,
    apiBaseUrl: BFF_API_BASE_URL,
    environment: ENVIRONMENT,
    npeClientId: NPE_OAUTH_CLIENT_ID,
    npeAuthority: NPE_OAUTH_AUTHORITY,
    sessionIdStorageKey,
  });
};
