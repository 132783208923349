import { MobileStepperProps, RacwaStepperTemplateProps } from "@racwa/react-components";
import { EMPTY_URL, SidebarContent } from "raci-react-library";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { SIDEBAR_TITLE } from "../../constants";
import { policyRoutes } from "../../routing/routes.config";
import useRoutes from "../../routing/useRoutes";
import usePage from "../usePage";

export const useStepperPage = (): RacwaStepperTemplateProps => {
  const location = useLocation();
  const commonPageProps = usePage();
  const { steps, activeStepIndex } = useRoutes();

  const contentTitle = policyRoutes.find(
    (route) => route.path.toLowerCase() === location.pathname.toLowerCase(),
  )?.heading;

  const sidebarContent = useMemo(
    () => (
      <SidebarContent
        pdsUrl={process.env.REACT_APP_RAC_PDS_PED_URL || EMPTY_URL}
        feedbackUrl={process.env.REACT_APP_RAC_FEEDBACK_URL || EMPTY_URL}
      />
    ),
    [],
  );

  /**
   * Disable stepper navigation and mobile stepper as our form is only
   * one page, so there's no reason to navigate back.
   */
  const onStepClick = undefined;
  const mobileStepperProps: MobileStepperProps = {
    hideBack: true,
  };

  return {
    contentTitle,
    sidebarTitle: SIDEBAR_TITLE,
    sidebarContent,
    steps,
    activeStepIndex,
    mobileStepperProps,
    onStepClick,
    ...commonPageProps,
  };
};

export default useStepperPage;
